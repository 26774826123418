<template>
  <sign-page
    title-text="采购物品"
    :request="request"
    table-size="small"
    :column-list="columnList"
    :form-parms-add="formParmsAdd"
    @dataUpdate="dataUpdate"
    :form-parms-update="formParmsUpdate"
    :table-actions-fixed="true"
    :tableActionsWidth="100"
    :need-data-file="true">
  </sign-page>
</template>

<script>
import {
  goodsRequest as request
} from '../../api'

import AssetsTypeForm from './assets_type/assetsTypeForm'

export default {
  computed: {
    formParmsAdd: {
      get () {
        let data = [{
          label: '名称',
          key: 'name',
          type: 'input'
        },
        {
          type: 'cmp',
          label: '资产类型',
          key: 'assetsTypeId',
          cmp: AssetsTypeForm,
          check: {
            required: true
          }
        }]
        return data
      }
    },
    formParmsUpdate: {
      get () {
        let data = []
        this.formParmsAdd.forEach((item) => {
          let newItem = {}
          for (let key in item) {
            newItem[key] = item[key]
          }
          data.push(newItem)
        })
        return data
      }
    },
    columnList: {
      get () {
        let data = [{
          title: '名称',
          sort: true,
          field: 'name'
        },
        {
          title: '资产类型名称',
          sort: true,
          field: 'assetsTypeName'
        },
        {
          title: '资产类型编码',
          sort: true,
          field: 'assetsTypeCode'
        }]
        return data
      }
    }
  },
  methods: {
    dataUpdate () {
      this.$store.dispatch('loadGoodsList', true)
    }
  },
  created () {
    if (this.$authFunsProxy.update || this.$authFunsProxy.add) {
      this.$store.dispatch('loadAssetsTypeList')
    }
  },
  data () {
    return {
      request: request
    }
  }
}
</script>
